import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OverviewContainerComponent } from './containers/overview-container/overview-container.component';

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'asset',
        pathMatch: 'full'
      },
      {
        path: 'asset',
        loadComponent: () =>
          import(
            './containers/asset-view-container/asset-view-container.component'
          ).then((m) => m.AssetViewContainerComponent),
        data: { label: 'assetView', assetType: 'asset' },
        canActivate: [AuthGuard]
      },
      {
        path: 'event',
        loadComponent: () =>
          import(
            './containers/event-view-container/event-view-container.component'
          ).then((m) => m.EventViewContainerComponent),
        data: { label: 'eventView', assetType: 'event' },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
