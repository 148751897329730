export enum ViewType {
  ASSETS_VIEW = 'assetView',
  EVENT_VIEW = 'eventView'
}

export enum SeverityIcon {
  CRITICAL = 'stop',
  MINOR = 'warning',
  NORMAL = 'circle'
}

export enum FeatureKeys {
  SPEEDING_CHART_ASSET_SUMMARY = 'speeding-event-over-time-in-asset-summary',
  SPEEDING_CHART_EVENT_VIEW = 'speeding-event-over-time-in-event-view',
  ASSET_PROPERTIES_FILTER = 'asset-properties-filter',
  DRIVER_ASSOCIATION_EVENT_VIEW = 'driver-association'
}

export enum DriverInfoSource {
  PRE_TRIP = 'PRE-TRIP-INSPECTION',
  EVIR_MOBILE = 'EVIR-MOBILE-INSPECTION',
  OPERATOR_ID = 'OPERATOR_ID',
  TABLET = 'TABLET_LOGIN',
  EXTERNAL = 'EXTERNAL',
  NOT_AVAIL = 'NOT_AVAIL'
}

export enum DriverLabel {
  PRE_TRIP = 'preTrip',
  EVIR_MOBILE = 'evirMobile',
  OPERATOR_ID = 'operator',
  TABLET = 'tablet',
  EXTERNAL = 'external',
  NOT_AVAIL = 'notAvail'
}
