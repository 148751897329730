import { Injectable } from '@angular/core';
import { PsApiService } from '../api-service/ps-api-service.service';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PsThresholdSettingService {
  constructor(private psApiService: PsApiService) {}
  private _duration;
  private _critical;
  private _minor;

  initiateThresholdValues() {
    const settingNames = [
      'company_threshold_posted_speed_duration',
      'company_threshold_posted_speed_critical',
      'company_threshold_posted_speed_minor'
    ];
    let settingArray = [];
    settingNames.forEach((name) => {
      settingArray.push(this.psApiService.getThresholdSetting(name));
      settingArray.push(this.psApiService.getThresholdDefinition(name));
    });
    forkJoin(settingArray).subscribe((result: any) => {
      // if duration exists
      if (result[0].body[0]?.value) {
        this._duration = result[0].body[0].value;
      } else {
        // use default value from definition
        this._duration = result[1].body.typeInfo.default;
      }
      // if critical exists
      if (result[2].body[0]?.value) {
        this._critical = result[2].body[0].value;
      } else {
        // use default value from definition
        this._critical = result[3].body.typeInfo.default;
      }
      // if minor exists
      if (result[4].body[0]?.value) {
        this._minor = result[4].body[0].value;
      } else {
        // use default value from definition
        this._minor = result[5].body.typeInfo.default;
      }
    });
  }

  get critical() {
    return this._critical;
  }
  get duration() {
    return this._duration;
  }
  get minor() {
    return this._minor;
  }
}
