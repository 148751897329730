import { environment } from 'src/environments/environment';

export const SidenavHeaderConfig = {
  displayName: true, // if we want to display name of the user
  avatarImageUrl: null, // if we want to show a specific avatar picture e.g. user's avatar
  optionalSubtitle: null
};

export const SidenavMenuConfig: any[] = [
  // {
  //   text: 'Activity Feed',
  //   icon: 'home',
  //   route: `${environment.gtcxUrl.activityFeed}`,
  //   children: [],
  //   isExpanded: false
  // },
  // {
  //   text: 'Live Maps',
  //   icon: 'map',
  //   routerLink: `${environment.gtcxUrl.maps}`,
  //   children: [],
  //   isExpanded: false,
  // },
];

export const SidenavFooterConfig = {
  items: []
};
