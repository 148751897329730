<div class="overview-container">
  <app-page-title>
    <div
      class="title-section"
      fxLayout="column"
      fxLayoutAlign="space-between start">
      <h1 class="main-title">
        {{ translateService.get(translations?.overview?.pageTitle) | async |
        translate }}
      </h1>
      <div class="sub-title">
        <span
          >{{
          translateService.get(translations?.overview?.pageSubTitle?.headPart) |
          async | translate }}</span
        >
        <a
          class="sub-title-link"
          href="https://support.zonarsystems.net/hc/en-us/articles/360016290852"
          target="_blank"
          >{{
          translateService.get(translations?.overview?.pageSubTitle?.middlePart)
          | async | translate }}</a
        >
        <span class="pad-right"
          >{{
          translateService.get(translations?.overview?.pageSubTitle?.tailPart) |
          async | translate }}</span
        >
      </div>
    </div>
  </app-page-title>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      *ngFor="let link of navLinks"
      mat-tab-link
      #rla="routerLinkActive"
      [active]="activeLink === link"
      [routerLink]="link.path"
      queryParamsHandling="preserve"
      routerLinkActive>
      {{ translateService.get(translations?.overview?.matTabs[link.label]) |
      async | translate }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
