// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'qa-na',
  production: false,
  region: 'NA',
  appUrl: 'https://posted-speed.qa.zonarsystems.net/',

  apiBase: {
    url: 'https://posted-speed-api.qa.zonarsystems.net/api/v1'
  },

  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1'
  },

  geocoderApi: {
    url: 'https://zonar-nonprod-qa.apigee.net'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'qa',

  auth: {
    clientID: 'q4X2LtE0fVJZAlFbxxAUpQqFDUx1L09s',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: '6ba6396a-9a30-4435-aa33-438ae110c46b',
    defaultZonarRole: '976c1b38-4492-484a-975b-7c0c283fe6cb',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_db78a821_7f2f_4109_b0ca_d69711c43aa7_ad46cfd'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: '0682e73f-9602-4954-96f0-910ca3a27c62',
    clientToken: 'pubc05d7f578e8d6c9ada0fda6eeb446fe1',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'fr-FR', 'es-ES', 'it-IT'],
    defaultLanguage: 'en-US'
  },
  here: {
    apiKeyV3: 'zRHQYeMWPTut1VrQIirpsuKQqaxgeY7dImB-CE0ND_0'
  },
  userPreferenceApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
